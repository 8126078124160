const routes = [
    {
        path: '',
        redirect: '/account'
    },
    {
        name: 'test',
        path: '/test',
        component: () => import('@/pages/test.vue'),
        meta: {
            title: 'test',
            requireAuth: true
        }
    },
    {
        name: 'prize_rate',
        path: '/prize_rate',
        component: () => import('@/pages/prize_rate.vue'),
        meta: {
            title: '抽奖概率设置',
            requireAuth: true
        }
    },
    {
        name: 'account',
        path: '/account',
        component: () => import('@/pages/account.vue'),
        meta: {
            title: '用户信息',
            requireAuth: true
        }
    },
    {
        name: 'mdlinfo',
        path: '/mdlinfo',
        component: () => import('@/pages/mdlinfo.vue'),
        meta: {
            title: '麦当劳券使用情况',
            requireAuth: true
        }
    },
    {
        name: 'lottery_record',
        path: '/lottery_record',
        component: () => import('@/pages/lottery_record.vue'),
        meta: {
            title: '抽奖记录查询/导出',
            requireAuth: true
        }
    },
    {
        name: 'real_prize',
        path: '/real_prize',
        component: () => import('@/pages/real_prize.vue'),
        meta: {
            title: '全国排名奖品/导出',
            requireAuth: true
        }
    },
    {
        name: 'export_task',
        path: '/export_task',
        component: () => import('@/pages/export_task.vue'),
        meta: {
            title: '导出任务列表',
            requireAuth: true
        }
    },
    {
        name: 'prize_num',
        path: '/prize_num',
        component: () => import('@/pages/prize_num.vue'),
        meta: {
            title: '抽奖张数调整',
            requireAuth: true
        }
    },
    {
        name: 'setting_log',
        path: '/setting_log',
        component: () => import('@/pages/setting_log.vue'),
        meta: {
            title: '操作日志',
            requireAuth: true
        }
    },
    {
        name: 'account_log',
        path: '/account_log',
        component: () => import('@/pages/account_log.vue'),
        meta: {
            title: '用户日志',
            requireAuth: true
        }
    },
    {
        name: 'prize_info',
        path: '/prize_info',
        component: () => import('@/pages/prize_info.vue'),
        meta: {
            title: '抽奖配置',
            requireAuth: true
        }
    } 
];
export default routes