<template>

  <div v-if="loginCheck">
    <el-menu
      default-active="2"
      mode="horizontal"
      background-color="#393D49"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-sub-menu index="1">
        <template #title><el-icon><Menu /></el-icon>菜单</template>
        <el-menu-item index="1-1" @click="showAgreement()"><el-icon><Warning /></el-icon>隐私协议</el-menu-item>
        <el-menu-item index="1-2" @click="clearRecord()"><el-icon><Delete /></el-icon>清除缓存</el-menu-item>
        <el-menu-item index="1-3"  @click="exitLogin()"><el-icon><Close /></el-icon>退出</el-menu-item>
      </el-sub-menu>
      <el-menu-item index="2"><el-icon><User /></el-icon>&nbsp;{{username}}</el-menu-item>
    </el-menu>
  </div>

  <div v-if="!loginCheck">
    <el-menu
      mode="horizontal"
      background-color="#393D49"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <!-- <el-menu-item index="1" @click="showRegister()"><el-icon><EditPen /></el-icon>&nbsp;注册</el-menu-item> -->
      <el-menu-item index="2" @click="showLogin()"><el-icon><User /></el-icon>&nbsp;登录</el-menu-item>
    </el-menu>
  </div>

  <div class="base-app-class">

    <el-menu
      :default-openeds="openeds"
      id="el-menu-element"
      style="border-right: 0px;height: 100vh;"
      :default-active="$route.path"
      class="el-menu-demo"

      mode="vertical">
      <el-menu-item index="/account" @click="gotoAccount()"><el-icon><User /></el-icon>用户信息查询/导出</el-menu-item>
      <el-menu-item index="/lottery_record" @click="gotoLotteryRecord()"><el-icon><Notebook /></el-icon>抽奖记录查询/导出</el-menu-item>
      <el-menu-item index="/mdlinfo" @click="gotoMdlInfo()"><el-icon><Burger /></el-icon>麦当劳券使用情况</el-menu-item>
      <el-menu-item index="/real_prize" @click="gotoRealPrize()"><el-icon><Trophy /></el-icon>全国排名获奖/导出</el-menu-item>

      <!-- <el-menu-item index="/prize_rate" @click="gotoPrizeRate()"><el-icon><Orange /></el-icon>抽奖概率调整</el-menu-item>
      <el-menu-item index="/prize_num" @click="gotoPrizeNum()"><el-icon><Money /></el-icon>抽奖张数调整</el-menu-item> -->

      <el-menu-item index="/prize_info" @click="gotoPrizeInfo()"><el-icon><Orange /></el-icon>抽奖配置</el-menu-item>
      <!-- <el-menu-item index="/setting_log" @click="gotoSettingLog()"><el-icon><SetUp /></el-icon>操作日志</el-menu-item> -->
      <el-menu-item index="/account_log" @click="gotoAccountLog()"><el-icon><Document /></el-icon>用户日志</el-menu-item>

      <el-menu-item index="/export_task" @click="gotoExportTask()"><el-icon><Memo /></el-icon>导出列表</el-menu-item>
    </el-menu>
    
    <router-view class="router-class" id="main-element"></router-view>

    <div class='bottom-base-div'>
      <div class="bottom-text-div">
        Copyright © 2021-2024 为爱麦跑 版权所有 
      </div>
      <!-- <div class="bottom-text-div">
        沪公网安备 123456号 
      </div> -->
      
      <div class="bottom-text-div" @click="gotoBeian()">
        沪ICP备 2023030527号-2
      </div>
    </div>

    <el-dialog v-model="dialogAgreementVisible" title="隐私协议">
      <div class="agreement-div" v-for="item in agreementData" :key="item">
        <h3>{{item}}</h3>
      </div>
    </el-dialog>

    <!-- <el-dialog v-model="dialogRegisterVisible" title="注册">
      <el-form label-width="120px">
        <el-form-item label="用户名">
          <el-input v-model="username" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="pwd" type="password"/>
        </el-form-item>
        <el-form-item label="重复密码">
          <el-input v-model="copypwd" type="password"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doRegister()">注册</el-button>
          <el-button @click="registerCancel()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog> -->

    <!-- <el-dialog v-model="dialogLoginVisible" title="登录">

      <el-form label-width="120px">
        <el-form-item label="用户名">
          <el-input v-model="username" />
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="pwd" type="password"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doLogin" >登录</el-button>
          <el-button @click="loginCancel">取消</el-button>
        </el-form-item>
      </el-form>

    </el-dialog> -->

    <el-dialog :fullscreen="true" 
    style="--el-dialog-padding-primary: 0px" 
    :close-on-press-escape="false" 
    :show-close="false" 
    v-model="dialogLoginVisible">

      <div class="login">
        <div class="mylogin">
          <h4>登录</h4>
          <el-form
            :rules="loginRules"
            ref="loginForm"
            label-width="0px">

            <el-form-item label="" style="margin-top: 10px; display: inline-flex;">
              <el-row>
                <el-col :span="2">
                  <el-icon><User /></el-icon>
                </el-col>
                <el-col :span="22">
                  <el-input
                    style="margin-left: 10px;"
                    class="inps"
                    placeholder="账号"
                    v-model="username"
                  >
                  </el-input>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item label="" style="display: inline-flex;">
              <el-row>
                <el-col :span="2">
                  <el-icon><Key /></el-icon>
                </el-col>
                <el-col :span="22">
                  <el-input
                    style="margin-left: 10px;"
                    class="inps"
                    type="password"
                    placeholder="密码"
                    v-model="pwd"
                  ></el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item style="margin-top: 55px;display: inline-flex;">
              <el-button type="primary" round class="submitBtn" @click="doLogin"
                >登录
              </el-button>
            </el-form-item>
            <el-form-item style="margin-top: -10px;display: inline-flex;">
              <div class="submitBtn">
                <el-text style="font-size: small">沪ICP备 2023030527号-2</el-text>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>

    </el-dialog>

  </div>
</template>

<script>

import { ElMessageBox, ElMessage } from 'element-plus'
import { ref } from 'vue'
import { h } from 'vue'
import axios from 'axios'

export default {
  name: 'App',
  components: {
    
  },
  data () {
    return {
      dialogAgreementVisible: ref(false),
      dialogRegisterVisible: ref(false),
      dialogLoginVisible: ref(false),
      agreementData: [],
      username: '',
      pwd: '',
      copypwd: '',
      openeds: ['3','9'],
      loginCheck: false,

      loginRules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        passWord: [{ required: true, message: "请输入密码", trigger: "blur" }],
      }
    }
  },

  created() {
    this.createAgreementData();
  },

  mounted() {
    //检测登录状态
    this.checkLogin();
    if(!this.loginCheck) {
      this.dialogLoginVisible = true;
    }
  },
  methods: {
    gotoBeian() {
      window.open("https://beian.miit.gov.cn", "_black")
    },
    gotoPrizeRate() {
      this.$router.push({
        path: '/prize_rate'
      })
    },
    gotoPrizeNum() {
      this.$router.push({
        path: '/prize_num'
      })
    },
    gotoPrizeInfo() {
      this.$router.push({
        path: '/prize_info'
      })
    },
    gotoAccount() {
      this.$router.push({
        path: '/account'
      })
    },
    gotoMdlInfo() {
      this.$router.push({
        path: '/mdlinfo'
      })
    },
    gotoLotteryRecord() {
      this.$router.push({
        path: '/lottery_record'
      })
    },
    gotoRealPrize() {
      this.$router.push({
        path: '/real_prize'
      })
    },
    gotoSettingLog() {
      this.$router.push({
        path: '/setting_log'
      })
    },
    gotoAccountLog() {
      this.$router.push({
        path: '/account_log'
      })
    },
    gotoExportTask() {
      this.$router.push({
        path: '/export_task'
      })
    },


    doLogin() {
      let that = this;
      if(this.checkNull(this.username) || this.checkNull(this.pwd)) {
        ElMessage.info('请输入用户名、密码')
        return;
      }
      if(this.username != "admin") {
        ElMessage.info('登录失败')
        return;
      }
      const options = {
        method: 'GET',
        url: '/manage/login/check',
        headers: {
          pwd: that.pwd
        }
       };
      axios(options)
      .then(function (response) {
        console.log(response)
        
        if(response.data.code != 200) {
          ElMessage.error(response.data.msg)
          return;
        }

        localStorage.setItem('username', that.username);
        localStorage.setItem('pwd', that.pwd);
        that.checkLogin();
        ElMessage.success("登录成功");
        that.dialogLoginVisible = false;

      })
      .catch(function (error) {
        console.log(error)
        ElMessage.error('数据处理失败')
      });
    },

    clearRecord() {
      this.username = localStorage.getItem('username');
      this.pwd = localStorage.getItem('pwd');
      localStorage.clear();
      localStorage.setItem('username', this.username);
      localStorage.setItem('pwd', this.pwd);
      this.checkLogin();
      ElMessage.success("清除成功");
    },

    exitLogin() {
      localStorage.clear();
      this.checkLogin();
      if(!this.loginCheck) {
        this.dialogLoginVisible = true;
      }
    },

    checkNull(data) {
      if(data == undefined || data == null || data.length <= 0) {
        return true;
      }
      return false;
    },

    checkLogin() {
      this.username = localStorage.getItem("username")
      this.pwd = localStorage.getItem("pwd")
      if(this.checkNull(this.username) || this.checkNull(this.pwd)) {
        this.loginCheck = false
      } else {
        this.loginCheck = true
      }
    },

    showAgreement() {
      this.dialogAgreementVisible = true;
    },

    showRegister() {
      this.dialogRegisterVisible = true;
      this.username = ''
      this.pwd = ''
      this.copypwd = ''
    },

    showLogin() {
      this.dialogLoginVisible = true;
      this.username = ''
      this.pwd = ''
      this.copypwd = ''
    },

    registerCancel() {
      this.dialogRegisterVisible = false
      this.username = ''
      this.pwd = ''
    },

    loginCancel() {
      this.dialogLoginVisible = false
    },

    createAgreementData() {
      var baseTitle = "为爱麦跑";

      this.recordArr = new Array();
      var info1 = "如有疑问请联系客服进行协商处理。";
      this.recordArr.push(info1);
      
      var info2 = "联系客服：zhangxw@citicpacific.com.cn";
      this.recordArr.push(info2);

      this.agreementData = this.recordArr;
    }
  }
}
</script>

<style>
body {
  margin: 0px;
  padding: 0px;
}

.customer_el_form_item_label .el-form-item__label{
    color: transparent;
}

.base-text {
  color: #01AAED
}

.agreement-div {
  text-align: left;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* .black-menu-itme {
  flex: auto;
} */

.el-message-box-image-class {
  width: 160px;
  height: 160px;
  margin-top: 20px;
  will-change: transform;
	overflow:hidden;
  border-radius: 10px 10px 10px 10px;
  /* box-shadow: 8px 8px 6px #DDDDDD; */
}

.router-class {
  margin-bottom: 66px;
  flex: 1;
}

.base-app-title-class {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
}

.base-app-class {
  display: flex;
  flex-direction: row;
}

.bottom-text-div {
  color: #a6a6a6;
  font-size: 10px;
}

.bottom-base-div {
  text-align: center;
  width: 60%; 
  position: fixed;
  bottom: 0px;
  margin-left: 20%;
  margin-right: 20%;
}

h3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login {
    width: 100vw;
    padding: 0;
    margin: 0;
    height: 100vh;
    font-size: 16px;
    background-position: left top;
    background-color: #242645;
    color: #fff;
    font-family: "Source Sans Pro";
    position: relative;
  }
 
  .mylogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 240px;
    height: 280px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 50px 40px 40px 40px;
    box-shadow: -15px 15px 15px rgba(6, 17, 47, 0.7);
    opacity: 1;
    background: linear-gradient(
      230deg,
      rgba(53, 57, 74, 0) 0%,
      rgb(0, 0, 0) 100%
    );
  }
 
  .inps input {
    border: none;
    background-color: transparent;
    font-size: 12px;
  }
 
  .submitBtn {
    background-color: transparent;
    color: #39f;
    width: 200px;
  }

</style>
